import {
    onlyAlphanumeric,
} from '@/_validations/validacionEspeciales'

import { 
    required, 
    minLength,
    maxLength, } from "vuelidate/lib/validators";

    const nameLength = (value) => {
        let Array = value.split(' ');
        for (let index = 0; index < Array.length; index++) {
          if(Array[index].length > 20){
            return false
          };
        }
        return true;
    };

    const isValidFile = (value) => {
        if(!value)
          return false;
        
        if(value.type)
          return value.type.startsWith('image');
        else
          return (value != '');
      };

export default () => {
    return {
        formMarca: {
            NbMarca:{
                required,
                onlyAlphanumeric,
                minLength: minLength(4),
                maxLength: maxLength(150),
                nameLength,
            },
            FgActMarca:{
                required
            },
            RutaMarca: { isValidFile  },
            FgMachine: { required  },
        },
        formModelo: {
            IdMarca:{
                required,
            },
            NbModelo:{
                required,
                onlyAlphanumeric,
                minLength: minLength(4),
                maxLength: maxLength(150),
                nameLength,
            },
            FgActModelo:{
                required
            },
            RutaModelo: { isValidFile  },
        }
    }
}

